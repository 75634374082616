<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-11-25 17:08:50
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 连麦设置
-->
<template>
  <div class="joinSetting">
    <div class="item flex-center">
      <div class="title">允许申请连麦</div>
      <div class="right">
        <el-switch
          v-model="setting.is_apply_call"
          active-color="#FA6400"
          inactive-color="#C5C5C5"
        ></el-switch>
      </div>
    </div>

    <!-- <div class="item flex">
      <div class="title">默认连麦布局</div>
      <div class="right flex">
        <div class="display">
          <div
            class="style style1"
            :class="{ active: setting.call_layout == 1 }"
            @click="setLayout(1)"
          ></div>
          <div class="tips">画中画左上</div>
        </div>
        <div class="display">
          <div
            class="style style2"
            :class="{ active: setting.call_layout == 2 }"
            @click="setLayout(2)"
          ></div>
          <div class="tips">画中画右上</div>
        </div>
        <div class="display">
          <div
            class="style style3"
            :class="{ active: setting.call_layout == 3 }"
            @click="setLayout(3)"
          ></div>
          <div class="tips">画中画右下</div>
        </div>
        <div class="display">
          <div
            class="style style4"
            :class="{ active: setting.call_layout == 4 }"
            @click="setLayout(4)"
          ></div>
          <div class="tips">画中画左下</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'joinSetting',

  data() {
    return {
      setting: {
        is_apply_call: false,
        call_layout: 1,
      },
    }
  },

  watch: {
    setting: {
      deep: true,
      handler(val) {
        this.$http({
          url: '/console/setCallSettings',
          data: Object.assign({}, val, {
            is_apply_call: val.is_apply_call ? 1 : 2,
            open_class_id: this.$route.params.id,
          }),
        })
        this.$store.dispatch('sendToWindowsMsg', {
          type: 16,
          value: val - 1,
        })
      },
    },
  },

  created() {
    this.getSetting()
  },

  methods: {
    setLayout(val) {
      this.setting.call_layout = val
    },

    async getSetting() {
      const { data } = await this.$http({
        url: '/console/getCallSettings',
        data: {
          open_class_id: this.$route.params.id,
        },
      })
      this.setting = Object.assign({}, data, {
        is_apply_call: data.is_apply_call == 1,
        call_layout: Number(data.call_layout),
      })

      this.$emit('reportSetting', this.setting)
    },
  },
}
</script>

<style lang="scss" scoped>
.joinSetting {
  .item {
    padding: 15px 20px;
    .title {
      font-size: 13px;
      color: #ffffff;
      margin-right: 20px;
      white-space: nowrap;
    }
    .right {
      flex-wrap: wrap;
      .display {
        margin-right: 14px;
        margin-bottom: 10px;
        &:last-child {
          margin-right: 0;
        }
        .style {
          width: 60px;
          height: 45px;
          background: #2a2b30;
          position: relative;
          cursor: pointer;
          border: 1px solid #2a2b30;
          &:after {
            content: '';
            position: absolute;
            width: 25px;
            height: 20px;
            background-color: #4b4c50;
          }
          &.active {
            border-color: #fa6400;
          }
          &.style1:after {
            left: 0;
            top: 0;
          }
          &.style2:after {
            right: 0;
            top: 0;
          }
          &.style3:after {
            right: 0;
            bottom: 0;
          }
          &.style4:after {
            left: 0;
            bottom: 0;
          }
        }
        .tips {
          margin-top: 10px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
  }
}
</style>
