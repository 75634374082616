<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-24 10:53:43
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 连麦人员搜索框
-->
<template>
  <div class="videoCalEmpty absoluteCenter">
    <img class="img" src="~@ass/img/2.0.8/icon_wkqlm.svg" alt="" />
    <div class="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'videoCalEmpty',

  props: {
    text: {
      type: String,
      default: '暂无数据',
    },
  },
}
</script>

<style lang="scss" scoped>
.videoCalEmpty {
  white-space: nowrap;
  margin-top: 57px;
  .img {
    display: block;
    margin: 0 auto;
  }
  .text {
    font-size: 13px;
    color: #ffffff;
    margin-top: 25px;
    text-align: center;
  }
}
</style>
