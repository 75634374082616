<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-26 14:57:34
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 连麦人员搜索框
-->
<template>
  <el-input
    class="my-input"
    :placeholder="placeholder"
    clearable
    size="medium"
    v-model="search"
    @clear="$emit('search', '')"
    @keyup.enter.native="$emit('search', search)"
  ></el-input>
</template>

<script>
export default {
  name: 'videoCalInput',

  props: {
    placeholder: {
      type: String,
      default: '输入昵称，按回车搜索',
    },
  },

  data() {
    return {
      search: '',
    }
  },
}
</script>

<style lang="scss" scoped>
.my-input {
  box-sizing: border-box;
  padding: 5px 14px 20px;
  ::v-deep .el-input__suffix {
    margin: -5px 15px 0 0;
  }
  ::v-deep .el-input__inner {
    color: #fff;
    background: #202020;
    border-radius: 4px;
    font-size: 13px;
    border-color: #202020;
  }
}
</style>
