<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-26 10:19:26
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 连麦人员item
-->
<template>
  <div class="videoCalltem">
    <div class="tu">
      <img :src="i.uphoto" alt="" />
    </div>
    <div class="middle">
      <div class="flex-center middle-content">
        <div class="name nowrap" :title="i.uname">{{ i.uname }}</div>
        <!-- <div class="describe nowrap" v-if="i.Invitee">
          & {{ i.Invitee }}邀请
        </div> -->
      </div>
      <div class="time" v-if="i.c_time">
        {{ i.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
      </div>
    </div>
    <div class="right-btn flex-center">
      <slot></slot>
      <div class="invitee-btn" @click="inviteJoin(i)">邀请</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoCalltem',

  props: {
    i: Object,
  },

  methods: {
    // 老师邀请
    async inviteJoin(item) {
      try {
        this.$emit('inviteJoin', item)
        await this.$http({
          url: '/console/callStatus',
          data: {
            status: 2,
            user_id: item.uid,
            open_class_id: this.$route.params.id,
          },
        })
      } catch (e) {
        this.$root.prompt('连麦请求失败')
        this.$emit('inviteJoin', null)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.videoCalltem {
  position: relative;
  cursor: pointer;
  padding: 15px 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  .tu {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    flex-shrink: 0;
    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  .middle {
    flex: 1;
    overflow: hidden;
    .name {
      font-size: 15px;
      color: #ffffff;
    }
    .describe {
      font-size: 15px;
      color: #ff9224;
      margin: 0 10px;
    }
    .time {
      font-size: 12px;
      color: #999999;
      margin-top: 10px;
    }
    .middle-content {
      flex: 1;
      overflow: hidden;
    }
  }

  .right-btn {
    flex-shrink: 0;
    margin-left: auto;
    padding-left: 10px;
    .invitee-btn {
      width: 60px;
      background: #fa6400;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
    }
  }
  &:hover {
    background-color: #28292c;
  }
}
</style>
