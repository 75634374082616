<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-27 18:05:04
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 连麦请求列表
-->
<template>
  <div class="onlineList">
    <minput @search=";(uname = arguments[0]), getData()"></minput>
    <div class="clear-btn" v-if="list.length > 0">
      <el-popconfirm class="vm" @confirm="clear" title="是否确定移除全部申请？">
        <div slot="reference" class="btn">移除全部申请</div>
      </el-popconfirm>
    </div>
    <item
      v-for="item in list"
      :key="item.uid"
      :i="item"
      @inviteJoin="$emit('inviteJoin', arguments[0])"
    >
      <div class="reject" @click="reject(item)">拒绝</div>
    </item>
    <empty
      v-if="showEmpty"
      :text="
        setting.is_apply_call
          ? '暂无数据'
          : '开启允许学员申请连麦后，学员即可发起申请'
      "
    ></empty>
  </div>
</template>

<script>
import item from './item'
import empty from './empty'
import minput from './input'

export default {
  name: 'applyJoin',

  props: {
    setting: Object,
  },

  components: {
    item,
    minput,
    empty,
  },

  data() {
    return {
      showEmpty: false,
      list: [],
      uname: '',
    }
  },

  created() {
    this.getData()
    this.timesStart()
  },

  beforeDestroy() {
    clearInterval(this.times)
  },

  methods: {
    // 获取学生连麦请求的列表
    async getData() {
      const { data } = await this.$http({
        url: '/console/applyCallList',
        data: {
          uname: this.uname,
          open_class_id: this.$route.params.id,
        },
      })
      this.list = data
      this.showEmpty = !this.list.length
    },

    timesStart() {
      this.times = setInterval(() => {
        this.getData()
      }, 3000)
    },

    // 拒绝学生连麦
    async reject(item) {
      await this.$http({
        url: '/console/callStatus',
        data: {
          status: 5,
          user_id: item.uid,
          open_class_id: this.$route.params.id,
        },
      })
      this.getData()
    },

    // 移除全部申请
    async clear() {
      await this.$http({
        url: '/console/callStatus',
        data: {
          open_class_id: this.$route.params.id,
        },
      })
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.clear-btn {
  font-size: 0;
  text-align: right;
  padding-right: 20px;
  padding-bottom: 10px;
  .btn {
    font-size: 15px;
    color: #fa6400;
    cursor: pointer;
  }
}
.reject {
  font-size: 14px;
  font-weight: bold;
  color: #999999;
  margin-right: 10px;
  cursor: pointer;
}
</style>
