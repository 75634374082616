<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-24 11:45:59
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 公开课视频连麦
-->
<template>
  <div class="videoJoin">
    <el-tabs class="el-tabs" v-model="activeName">
      <el-tab-pane label="在线学员" name="0"></el-tab-pane>
      <el-tab-pane label="申请列表" name="1"></el-tab-pane>
      <el-tab-pane label="连麦设置" name="2"></el-tab-pane>
      <!-- <el-tab-pane label="连麦中" name="3"></el-tab-pane> -->
    </el-tabs>
    <onlineList v-if="activeName == 0" @inviteJoin="inviteJoin" />
    <applyJoin
      v-if="activeName == 1"
      @inviteJoin="inviteJoin"
      :setting="setting"
    />
    <joinSetting
      v-show="activeName == 2"
      @reportSetting="setting = arguments[0]"
    />
    <transition name="drop">
      <joining
        v-if="isJoining"
        :joinInfo="isJoining"
        @close="isJoining = null"
      />
    </transition>
  </div>
</template>

<script>
import onlineList from './components/onlineList'
import applyJoin from './components/applyJoin'
import joinSetting from './components/joinSetting'
import joining from './components/joining'

export default {
  name: 'joinWaitingList',

  components: {
    onlineList,
    applyJoin,
    joinSetting,
    joining,
  },

  data() {
    return {
      activeName: '0',
      isJoining: null,
      setting: {},
    }
  },

  methods: {
    inviteJoin(val) {
      this.isJoining = val
        ? Object.assign({}, val, this.setting, {
            teacherName: this.setting.uname,
            teacherPhoto: this.setting.uphoto,
            stuName: val.uname,
            stuPhoto: val.uphoto,
          })
        : null
      console.log(this.isJoining)
    },
  },
}
</script>

<style lang="scss" scoped>
.videoJoin {
  background: #000;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  @extend %liveRoomScroll;
  ::v-deep .el-tabs {
    background: #000;
    padding-left: 20px;
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .el-tabs__item {
      color: #999;
      font-size: 13px;
    }
    .el-tabs__item.is-active {
      color: #fa6400;
    }
    .el-tabs__active-bar {
      background-color: #fa6400;
    }
  }
}

.drop-enter-active {
  transition: all 0.35s ease;
}

.drop-leave-active {
  transition: all 0.3s ease 80ms;
}

.drop-enter,
.drop-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
