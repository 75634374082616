<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-10-26 14:42:10
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 在线连麦人员列表
-->
<template>
  <div class="onlineList">
    <minput @search=";(uname = arguments[0]), getData()"></minput>
    <item
      v-for="item in list"
      :key="item.uid"
      :i="item"
      @inviteJoin="$emit('inviteJoin', arguments[0])"
    ></item>
    <empty v-if="showEmpty"></empty>
  </div>
</template>

<script>
import item from './item'
import empty from './empty'
import minput from './input'

export default {
  name: 'onlineList',

  components: {
    item,
    minput,
    empty,
  },

  data() {
    return {
      showEmpty: false,

      list: [],
      uname: '',
    }
  },

  created() {
    this.getData()
    this.timesStart()
  },

  beforeDestroy() {
    clearInterval(this.times)
  },

  methods: {
    // 获取学生连麦请求的列表
    async getData() {
      const { data } = await this.$http({
        url: '/console/memberList',
        data: {
          search_type: 1,
          teacher_id: 0,
          select: 1,
          type: 1,
          uname: this.uname,
          open_class_id: this.$route.params.id,
        },
      })
      this.list = data.list
      this.showEmpty = !this.list.length
    },

    timesStart() {
      this.times = setInterval(
        () => {
          this.getData()
        },
        this.list.length < 50 ? 5000 : 30000
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
