<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-11-22 17:34:03
 * @FilePath: \teacher.dingdingkaike\src\views\videoCall\main.vue
 * @Description: 连麦中
-->
<template>
  <div class="joining absolute">
    <div class="joining-content absoluteCenter">
      <div class="flex">
        <div class="photo-contain">
          <img :src="joinInfo.teacherPhoto" class="photo" />
          <div class="name nowrap">{{ joinInfo.teacherName }}</div>
        </div>
        <img class="join-icon" src="~@ass/img/2.0.8/icon_scy_lmz.svg" alt="" />
        <div class="photo-contain">
          <img :src="joinInfo.stuPhoto" class="photo" />
          <div class="name nowrap">{{ joinInfo.stuName }}</div>
          <img
            v-if="!micMute"
            title="麦克风已开"
            src="~@ass/img/2.0.8/icon_lmhd_jyg.svg"
            class="student-mic"
            @click="toggleMicState(true)"
          />
          <img
            v-else
            title="麦克风已关"
            src="~@ass/img/2.0.8/icon_lmhd_jyk.svg"
            class="student-mic"
            @click="toggleMicState(false)"
          />
        </div>
      </div>
      <div class="status-text status-text2" v-if="status == 2">
        正在连麦中
        <span class="dotting"></span>
      </div>
      <div class="status-text status-text1" v-else-if="status == 1">
        {{ time | formatTimeLength2('hh:mm:ss') }}
      </div>
      <div class="status-text status-text3" v-else-if="status == 3">
        连接失败：学员超时未接听
      </div>
      <div class="status-btn status-btn2" v-if="status == 2" @click="cancel">
        取消
      </div>
      <div
        class="status-btn status-btn1"
        v-else-if="status == 1"
        @click="hangUp"
      >
        挂断
      </div>
      <div
        class="status-btn status-btn3"
        v-else-if="status == 3"
        @click="close"
      >
        返回
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'joining',

  props: {
    joinInfo: Object,
  },

  data() {
    return {
      /**
       * status 代表连麦状态
       * 1 链接成功
       * 2 链接中
       * 3 链接失败
       */
      status: 2,
      time: 0,

      roomInfo: null,

      micMute: false,
      disableCamera: true,
    }
  },

  beforeDestroy() {
    this.cancel()
    clearInterval(this.videoCallTimes)
  },

  created() {
    // 给客户端发送RTC房间数据
    this.getRoomInfo()
  },

  mounted() {
    this.start()
    // 监听页面关闭
    window.addEventListener('unload', () => {
      this.cancel()
    })
    /**
     * 监听rtc链接成功 还是 断开
     * state 0 表示挂断
     * state 1 表示连接
     * state 2 手动去挂断
     *  */
    this.$store.dispatch('sendToWindowsMsg', {
      type: 15,
      callback: state => {
        console.log('客户端发送的rtc链接成功 or 挂断数据', state)
        if (state == 0) {
          this.close()
        } else if (state == 2) {
          this.hangUp()
          this.$store.dispatch('sendToWindowsMsg', {
            type: 13,
          })
        } else {
          this.time = 0
          this.status = 1
          clearInterval(this.videoCallTimes)
          this.videoCallTimes = setInterval(() => {
            this.time += 1
          }, 1000)
        }
      },
    })
    /**
     * 老师端画面开关的通知，web 端在回调里更新按钮状态
     * mute 为 true 表示禁用 false 表示启用
     * */
    this.$store.dispatch('sendToWindowsMsg', {
      type: 18,
      callback: state => {
        console.log('客户端发送的老师开关麦数据', state)
        this.disableCamera = state
      },
    })
    // 进入了邀请页面通知客户端 放再最下面 等上面监听函数注册完执行
    this.$store.dispatch('sendToWindowsMsg', {
      type: 21,
    })
  },

  methods: {
    // 开启定时器
    start() {
      this.videoCallTimes = setInterval(() => {
        this.time += 1
        if (this.time > 60) {
          this.status = 3
          clearInterval(this.videoCallTimes)
        } else {
          this.$http({
            url: '/console/getCallStatus',
            isMultipleSelf: 'initialSelf',
            data: {
              user_id: this.joinInfo.uid,
              open_class_id: this.$route.params.id,
            },
            callback: ({ data }) => {
              if (data == 4) {
                this.status = 4
                this.$root.prompt('学生已拒绝')
                this.close()
              } else if (data == 3) {
                // 学生进入了房间
                if (this.roomInfo) {
                  this.$store.dispatch('sendToWindowsMsg', {
                    type: 19,
                    value: this.roomInfo,
                  })
                  this.roomInfo = null
                }
              }
            },
          })
        }
      }, 1000)
    },

    // 挂断
    async hangUp() {
      await this.$http({
        url: '/LiveRtc/removeRoom',
        data: {
          userId: this.joinInfo.userID,
          open_class_id: this.$route.params.id,
        },
      })
      this.close()
    },

    // 返回
    close() {
      this.$emit('close')
    },

    // 取消
    cancel() {
      if (this.status === 2) {
        this.$http({
          url: '/console/callStatus',
          data: {
            status: 6,
            user_id: this.joinInfo.uid,
            open_class_id: this.$route.params.id,
          },
        })
      }
      this.close()
    },

    // 开关麦状态
    toggleMicState(state) {
      this.micMute = state
      this.$store.dispatch('sendToWindowsMsg', {
        type: 17,
        value: this.micMute,
      })
    },

    // 开关老师摄像头状态
    toggleCamera(state) {
      this.disableCamera = state
      this.$store.dispatch('sendToWindowsMsg', {
        type: 20,
        value: this.disableCamera,
      })
    },

    // 给客户端发送RTC房间数据
    getRoomInfo() {
      this.$http({
        url: '/LiveRtc/getRoomInfo',
        data: {
          open_class_id: this.$route.params.id,
        },
        callback: ({ data: { txImInfo } }) => {
          this.roomInfo = Object.assign({}, this.isJoining, txImInfo)
        },
        error: () => {
          this.$root.prompt('获取房间信息失败，请检查网络')
          this.close()
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.joining {
  z-index: 99;
  white-space: nowrap;
  background: #000;
}

.joining-content {
  max-width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.join-icon {
  margin: 11px 22px 0;
  align-self: flex-start;
}

.status-text {
  margin: 40px 0;
  text-align: center;
  &.status-text1 {
    font-size: 15px;
    color: #ffffff;
  }
  &.status-text2 {
    font-size: 15px;
    color: #ffffff;
  }
  &.status-text3 {
    font-size: 15px;
    color: #ff3535;
  }
}
.status-btn {
  width: 80px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  &.status-btn1 {
    transition: background 0.2s;
    background: #ff3535;
    &:hover {
      background: #b02828;
    }
  }
  &.status-btn2,
  &.status-btn3 {
    background: #2a2b30;
  }
}

.photo-contain {
  flex: 1;
  overflow: hidden;
  .photo {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
  }
  .name {
    font-size: 14px;
    color: #ffffff;
    margin-top: 10px;
    text-align: center;
  }
  .student-mic {
    width: 30px;
    cursor: pointer;
    margin: 10px auto 0 auto;
  }
}

.dotting {
  display: inline-block;
  width: 10px;
  min-height: 2px;
  padding-right: 2px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  background-color: #fff;
  background-clip: content-box;
  box-sizing: border-box;
  animation: dot 3s infinite step-start both;
}
.dotting::before {
  content: '';
}
:root .dotting {
  margin-left: 2px;
  padding-left: 2px;
}

@keyframes dot {
  25% {
    border-color: transparent;
    background-color: transparent;
  }
  /* 0个点 */
  50% {
    border-right-color: transparent;
    background-color: transparent;
  }
  /* 1个点 */
  75% {
    border-right-color: transparent;
  }
  /* 2个点 */
}
</style>
